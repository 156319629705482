import React from 'react';
import { shape, string, number } from 'prop-types';
import { Image } from 'nordic/image';
import ImageLogo from './commons/image-logo';

const namespace = 'ui-seller-data-banner';

const Banner = ({ banner, logo }) => (
  <div className={`${namespace}__container`}>
    <div className={`${namespace}__container-image`}>
      <Image alt="" src={banner.url.src} className={`${namespace}__image`} key={banner.url.src} lazyload />
    </div>
    {logo && (
      <div className={`${namespace}__logo-container`}>
        <ImageLogo logo={logo} />
      </div>
    )}
  </div>
);

Banner.propTypes = {
  banner: shape({
    url: shape({
      src: string,
    }),
    width: number,
    height: number,
  }).isRequired,
  logo: shape({
    url: shape({
      src: string,
    }),
    width: number,
    height: number,
    action: shape({
      target: string,
    }),
  }),
};

export default Banner;
